import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer"
import Secondary from '../components/Secondary';
import Notices from '../components/Noties';
import SecTable from '../components/SecTable';
import Topbar from '../components/Topbar';
import Calender from '../components/Calender';

const SecondaryPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Secondary">
            <Topbar />
            <NavOne />
            <PageHeader title="Secondary" />
            <Secondary />
            <Calender />
            {/* <SecTable /> */}
            <Notices />
            <Footer />
        </Layout>
    );
};

export default SecondaryPage;
