import React from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import blogTwo1 from "../assets/images/blog-2-1.jpg";
import blogTwo2 from "../assets/images/blog-2-2.jpg";
import blogTwo3 from "../assets/images/blog-2-3.jpg";

const SecTable = () => {
  const params = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      }
    }
  };

  return (
    // GRADE I & II
    <section className="blog-two">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
          ANNUAL SCHEDULE FOR CELEBRATIONS FOR THE ACADEMIC YEAR (2021-22) GRADE VI & VIII
          </h2>
        </div>
        <div className="blog-two__carousel">
          <Swiper {...params}>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>09</span>
                    April 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Friday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    World Health Day
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>22</span>
                    April 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Thursday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    World Earth Day
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>30</span>
                    April 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Friday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    Mother’s Day
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>19</span>
                    June 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Saturday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    Father's day
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>21</span>
                    June 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Monday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    Interna. Yoga Day
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>24</span>
                    July 21
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">Saturday</a>
                    {/* <a href="#none">3 Comments</a> */}
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                    Guru Purnima
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section> 

  );
};
export default SecTable;
